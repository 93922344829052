import React, { FC } from 'react'

import cx from 'classnames'

import Link from 'components/Link'

import s from './HonestSign.scss'

const HEIGHT_35 = 35
const WIDTH_105 = 105

export const LINK_HONEST_SIGN = '/catalog/mark/is_honest_sign'

interface IHonestSign {
  className?: string
  height?: number
  width?: number
}

const HonestSign: FC<IHonestSign> = ({
  className,
  height = HEIGHT_35,
  width = WIDTH_105
}) => {
  const imageStyle = { height: `${height}px`, width: `${width}px` }
  return (
    <div
      className={cx(s.honestSign, {
        [className!]: !!className
      })}
    >
      <Link
        to={LINK_HONEST_SIGN}
      >
        <img
          alt='Честный знак'
          src='/images/honestSign/honestSign.png'
          style={imageStyle}
          title='Национальная система маркировки и прослеживания продукции'
        />
      </Link>
    </div>
  )
}

export default HonestSign
